import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
// import { TAppDispatch, TRootState } from '../store';

// export const useAppDispatch = () => useDispatch<TAppDispatch>();
// export const useAppSelector: TypedUseSelectorHook<TRootState> = useSelector;

// export function usePrevious<T>(value: T) {
//   const ref = useRef<T>();
//   // the ref is returned before useEffect updates it
//   useEffect(() => {
//     ref.current = value;
//   }, [value]);
//   return ref.current;
// }

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;

export function usePrevious(value) {
  const ref = useRef();
  // the ref is returned before useEffect updates it
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
