import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { InputLabel, TextField, Button, Typography } from "@material-ui/core";
import {
  ThumbUp,
  ThumbDown,
  Chat,
  Phone,
  Email,
  Send,
} from "@material-ui/icons";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import { PLACEHOLDER_PROFILE_IMAGE } from "../state/constants";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import {
  setIsMatchesMadeTutorial,
  setIsTutorial,
  setMatchesInfoDocID,
  setShadchan2,
  setSingle1,
  setSingle2,
} from "../state/app-slice";

const MatchesMade = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);

  const [SingleAName, setSingleAName] = useState("Mr. Single 1");
  const [SingleAPhotoUrl, setSingleAPhotoUrl] = useState(
    PLACEHOLDER_PROFILE_IMAGE
  );
  const [SingleACity, setSingleACity] = useState("Brooklyn, New York");
  const [SingleAPhone, setSingleAPhone] = useState(3472558783);
  const [SingleAEmail, setSingleAEmail] = useState("test@gmail.com");

  const [SingleBName, setSingleBName] = useState("Mrs. Single 2");
  const [SingleBPhotoUrl, setSingleBPhotoUrl] = useState(
    PLACEHOLDER_PROFILE_IMAGE
  );
  const [SingleBCity, setSingleBCity] = useState("Mill Basin, New York");
  const [SingleBPhone, setSingleBPhone] = useState(3472558782);
  const [SingleBEmail, setSingleBEmail] = useState("testfemale@gmail.com");

  const [ShadchanAName, setShadchanAName] = useState("Mr. First shadchan");
  const [ShadchanACity, setShadchanACity] = useState("Crown Heights, New York");
  const [ShadchanAPhone, setShadchanAPhone] = useState(3472558781);
  const [ShadchanAEmail, setShadchanAEmail] = useState("testshad@gmail.com");

  const [ShadchanBName, setShadchanBName] = useState("Mrs. Second Shadchan");
  const [ShadchanBPhotoUrl, setShadchanBPhotoUrl] = useState(
    PLACEHOLDER_PROFILE_IMAGE
  );
  const [ShadchanBCity, setShadchanBCity] = useState("Flatbush, New York");
  const [ShadchanBPhone, setShadchanBPhone] = useState(3472558780);
  const [ShadchanBEmail, setShadchanBEmail] = useState(
    "testshadfemale@gmail.com"
  );

  const subject = "Message from Shadchan App";
  const template =
    "Hi, I'm reaching out with regards to a match on the Shadchan App!";

  const [rows, setRows] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const history = useHistory();

  const [stepIndex, setStepIndex] = useState(0);
  const steps = [
    {
      target: "#match-info",
      content:
        "This is where you can view the matched Shadchan's and Single's information.",
      placement: "top-end",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Viewing Match Information",
      disableScrolling: true,
    },
    {
      target: "#shadchan2",
      content: "Click here to chat with the matched Shadchan",
      placement: "right",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Viewing Match Information",
    },
    {
      target: "#whyMatchMade",
      content: "This is where you can see why the match was made",
      placement: "top-end",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Learn more about the match",
    },
    {
      target: "#acceptdecline",
      content: (
        <div>
          This is where you can accept or decline matches that were made
          <br />
          <i>
            Note: Both Shadchans must accept in order for a match to be made
          </i>
        </div>
      ),
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      title: "Accept or Decline",
    },
    {
      target: "#description",
      content:
        "This is where you can provide feedback on the match at any time",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      placement: "bottom-end",
      title: "Provide Match Feedback",
    },
  ];

  useEffect(() => {
    if (!appState.uid || appState.uid === "") {
      window.alert("You are not signed in");
      history.replace("/");
    } else {
      loadMatchDetails();
    }
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(setMatchesInfoDocID(""));
      dispatch(setShadchan2(""));
      dispatch(setSingle1(""));
      dispatch(setSingle2(""));
    };
  }, []);

  const loadMatchDetails = async () => {
    await axios
      .get(
        process.env.REACT_APP_APILINK +
          "getMatchesProfile/" +
          appState.matchesInfoDocID
      )
      .then(async (response) => {
        const data = response.data;
        if (!appState.uid || appState.uid === "") {
          window.alert("You are not signed in");
          window.location.replace("/");
        } else {
          if (appState.matchesInfoDocID !== "dummyMatch") {
            await axios
              .get(
                process.env.REACT_APP_APILINK +
                  "getMatchesProfile/" +
                  appState.matchesInfoDocID
              )
              .then((response) => response.data)
              .then(async (data) => {
                if (
                  (data.shadchan1 === appState.uid && data.shadchan1Accepted) ||
                  (data.shadchan2 === appState.uid && data.shadchan2Accepted)
                ) {
                  document.getElementById("pairBAccept").hidden = true;
                  document.getElementById("pairBDecline").hidden = true;
                }
                if (data.rating != null)
                  document.getElementById(
                    "rating-radio" + data.rating
                  ).checked = true;
                if (data.notes != null)
                  document.getElementById("description").value = data.notes;
                await axios
                  .post(process.env.REACT_APP_APILINK + "getMatchesInfo", {
                    shadchan1: appState.uid,
                    shadchan2: appState.shadchan2,
                    single1: appState.single1,
                    single2: appState.single2,
                  })
                  .then((response) => response.data)
                  .then((data) => {
                    setSingleAName(
                      `${data.single1.firstName} ${data.single1.lastName}`
                    );
                    setSingleAPhotoUrl(
                      data.single1.photoUrl ?? PLACEHOLDER_PROFILE_IMAGE
                    );
                    setSingleACity(data.single1.city);
                    setSingleAPhone(data.single1.cellPhone);
                    setSingleAEmail(data.single1.email);

                    setSingleBName(
                      `${data.single2.firstName} ${data.single2.lastName}`
                    );
                    setSingleBPhotoUrl(
                      data.single2.photoUrl ?? PLACEHOLDER_PROFILE_IMAGE
                    );
                    setSingleBCity(data.single2.city);
                    setSingleBPhone(data.single2.cellPhone);
                    setSingleBEmail(data.single2.email);

                    setShadchanAName(
                      `${data.shadchan1.firstName} ${data.shadchan1.lastName}`
                    );
                    setShadchanACity(data.shadchan1.city);
                    setShadchanAPhone(data.shadchan1.cellPhone);
                    setShadchanAEmail(data.shadchan1.email);

                    setShadchanBName(
                      `${data.shadchan2.firstName} ${data.shadchan2.lastName}`
                    );
                    setShadchanBPhotoUrl(
                      data.shadchan2.photoUrl ?? PLACEHOLDER_PROFILE_IMAGE
                    );
                    setShadchanBCity(data.shadchan2.city);
                    setShadchanBPhone(data.shadchan2.cellPhone);
                    setShadchanBEmail(data.shadchan2.email);
                  });
              });
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching match details:", error);
      });
    setIsActive(false);
    await GetDetails(appState.uid, appState.uid);
  };

  const AcceptButton = (accepted) => {
    axios
      .post(process.env.REACT_APP_APILINK + "acceptedDate", {
        shadchan: appState.uid,
        match_id: appState.matchesInfoDocID,
        single: appState.single1,
        accepted: accepted,
      })
      .then((response) => response.data)
      .then(function (data) {
        if (data.status === true) {
          document.getElementById("pairBAccept").hidden = true;
          document.getElementById("pairBDecline").hidden = true;
          window.alert(`Match ${accepted ? "accepted" : "declined"}!`);
        } else {
          window.alert(
            "There was an error approving your request please try again later"
          );
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rating = document.querySelector(
      'input[name="rating-radio"]:checked'
    ).value;
    const description = document.getElementById("description").value;

    await axios
      .post(process.env.REACT_APP_APILINK + "setDateReview", {
        notes: description,
        docId: appState.matchesInfoDocID,
        rating: rating,
      })
      .then((response) => response.data)
      .then((data) => {
        if (data.status === 200) {
          window.alert("Your feedback has been received, thank you!");
        } else {
          window.alert(
            "There was an error receiving your feedback, please try again later."
          );
        }
      });
  };

  const GetDetails = async (id, docID) => {
    // eslint-disable-next-line
    if (appState.matchesInfoDocID !== "dummyMatch") {
      const promise = await axios
        .post(process.env.REACT_APP_APILINK + "getMatchDetails", {
          docId: docID,
          single1: appState.single1,
          single2: appState.single2,
        })
        .then((response) => response.data)
        .then((data) => {
          var row = [];
          for (var i = 0; i < appState.numQuestions; i++) {
            row.push({
              question: appState.questions[i],
              menPreference: data.menPreference.charAt(i),
              womenTrait: data.womenTrait.charAt(i),
              womenPreference: data.womenPreference.charAt(i),
              menTrait: data.menTrait.charAt(i),
            });
          }
          setRows(row);
        });
    } else {
      var row = [];
      const menPreference = "5552532515";
      const womenTrait = "3123321312";
      const womenPreference = "2212531112";
      const menTrait = "2221131112";
      for (var i = 0; i < appState.numQuestions; i++) {
        row.push({
          question: appState.questions[i],
          menPreference: menPreference.charAt(i),
          womenTrait: womenTrait.charAt(i),
          womenPreference: womenPreference.charAt(i),
          menTrait: menTrait.charAt(i),
        });
      }
      setRows(row);
    }
  };

  const getColor = (trait, preference) => {
    if (trait === preference) return "green";
    if (trait === 1) {
      if (preference === 0 || preference === 1) return "green";
      else if (preference === 3 || preference === 4) return "red";
    }
    if (trait === 3) {
      if (preference === 3 || preference === 4) return "green";
      else if (preference === 0 || preference === 1) return "red";
    }
    return "black";
  };

  const getWord = (num) => {
    switch (num) {
      case "0":
        return "Definitely Yes";
      case "1":
        return "Yes";
      case "2":
        return "In Between";
      case "3":
        return "No";
      case "4":
        return "Definitely No";
      case "5":
        return "Doesn't Matter";
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type, steps } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setIsMatchesMadeTutorial(false));
      dispatch(setIsTutorial(false));
      setStepIndex(0);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading your content...">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={appState.isMatchesMadeTutorial}
        scrollToFirstStep
        scrollOffset={300}
        locale={{
          last: "Finish Tutorial",
        }}
        showSkipButton
        stepIndex={stepIndex}
        steps={steps}
        styles={{
          options: {
            primaryColor: "#90387E",
          },
        }}
      />
      <div>
        <div className="page-content" id="matches-made">
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container container-fluid text-center">
              <div id="match-info">
                <Typography
                  variant="h2"
                  display="block"
                  align="center"
                  style={{
                    paddingTop: "25px",
                    margin: "0rem",
                    fontSize: "2.5rem",
                    marginBottom: "15px",
                  }}
                >
                  Your Information
                </Typography>
                <div className="card-deck">
                  <div className="card flex flex-col items-center justify-start">
                    <div className="card-header w-full">You</div>
                    <img
                      className="card-img-top"
                      src={appState.photoUrl}
                      alt="Shadchan 1"
                      style={{
                        width: "150px",
                        height: "150px",
                      }}
                    />
                    <div className="card-body">
                      <p className="card-title h3">{ShadchanAName}</p>
                      <p className="card-text h6">{ShadchanACity} </p>
                      <a
                        href={
                          "mailto: " +
                          ShadchanAEmail +
                          "?subject=" +
                          subject +
                          "&body=" +
                          template
                        }
                      >
                        Email Address:{" "}
                        <span style={{ color: "#0000EE" }}>
                          {ShadchanAEmail}
                        </span>
                      </a>
                      <p className="card-text">
                        Phone Number: {ShadchanAPhone}
                      </p>
                    </div>
                  </div>
                  <div className="card flex flex-col items-center justify-start">
                    <div className="card-header w-full">Your Single</div>
                    <img
                      className="card-img-top"
                      src={SingleAPhotoUrl}
                      alt="My single"
                      style={{
                        width: "150px",
                        height: "150px",
                      }}
                    />
                    <div className="card-body">
                      <p className="card-title h3">{SingleAName}</p>
                      <p className="card-text h6">{SingleACity} </p>
                      <a
                        href={
                          "mailto: " +
                          SingleAEmail +
                          "?subject=" +
                          subject +
                          "&body=" +
                          template
                        }
                      >
                        Email Address:{" "}
                        <span style={{ color: "#0000EE" }}>{SingleAEmail}</span>
                      </a>
                      <p className="card-text">Phone Number: {SingleAPhone}</p>
                    </div>
                    <div className="card-footer w-full">
                      {appState.isMobile && (
                        <Button
                          style={{
                            margin: "10px",
                            color: "#90387E",
                            borderColor: "#90387E",
                          }}
                          variant="outlined"
                          size="large"
                          type="submit"
                          startIcon={<Phone />}
                          href={"tel: " + SingleAPhone}
                        >
                          Call
                        </Button>
                      )}
                      {appState.osVersion === "mac" && (
                        <Button
                          style={{
                            margin: "10px",
                            color: "#90387E",
                            borderColor: "#90387E",
                          }}
                          variant="outlined"
                          size="large"
                          type="submit"
                          startIcon={<Chat />}
                          href={"sms: " + SingleAPhone + "&body=" + template}
                        >
                          Text
                        </Button>
                      )}
                      <Button
                        style={{
                          margin: "10px",
                          color: "#90387E",
                          borderColor: "#90387E",
                        }}
                        variant="outlined"
                        size="large"
                        type="submit"
                        startIcon={<Email />}
                        href={
                          "mailto: " +
                          SingleAEmail +
                          "?subject=" +
                          subject +
                          "&body=" +
                          template
                        }
                      >
                        Email
                      </Button>
                    </div>
                  </div>
                </div>
                <Typography
                  variant="h2"
                  display="block"
                  align="center"
                  style={{
                    paddingTop: "25px",
                    margin: "0rem",
                    fontSize: "2.5rem",
                    marginBottom: "15px",
                  }}
                >
                  Match Information
                </Typography>
                <div className="card-deck">
                  <div className="card flex flex-col items-center justify-start">
                    <div className="card-header w-full">Matched Shadchan</div>
                    <img
                      className="card-img-top"
                      src={ShadchanBPhotoUrl}
                      alt="Shadchan 1"
                      style={{
                        width: "150px",
                        height: "150px",
                      }}
                    />
                    <div className="card-body">
                      <p className="card-title h3">{ShadchanBName}</p>
                      <p className="card-text h6">{ShadchanBCity} </p>
                      <a
                        href={
                          "mailto: " +
                          ShadchanBEmail +
                          "?subject=" +
                          subject +
                          "&body=" +
                          template
                        }
                      >
                        Email Address:{" "}
                        <span style={{ color: "#0000EE" }}>
                          {ShadchanBEmail}
                        </span>
                      </a>
                      <p className="card-text">
                        Phone Number: {ShadchanBPhone}
                      </p>
                    </div>
                    <div className="card-footer w-full" id="shadchan2">
                      {appState.isMobile && (
                        <Button
                          style={{
                            margin: "10px",
                            color: "#90387E",
                            borderColor: "#90387E",
                          }}
                          variant="outlined"
                          size="large"
                          type="submit"
                          startIcon={<Phone />}
                          href={"tel: " + ShadchanBPhone}
                        >
                          Call
                        </Button>
                      )}
                      {appState.osVersion === "mac" && (
                        <Button
                          style={{
                            margin: "10px",
                            color: "#90387E",
                            borderColor: "#90387E",
                          }}
                          variant="outlined"
                          size="large"
                          type="submit"
                          startIcon={<Chat />}
                          href={"sms: " + ShadchanBPhone + "&body=" + template}
                        >
                          Text
                        </Button>
                      )}
                      <Button
                        style={{
                          margin: "10px",
                          color: "#90387E",
                          borderColor: "#90387E",
                        }}
                        variant="outlined"
                        size="large"
                        type="submit"
                        startIcon={<Email />}
                        href={
                          "mailto: " +
                          ShadchanBEmail +
                          "?subject=" +
                          subject +
                          "&body=" +
                          template
                        }
                      >
                        Email
                      </Button>
                    </div>
                  </div>
                  <div className="card flex flex-col items-center justify-start">
                    <div className="card-header w-full">Matched Single</div>
                    <img
                      className="card-img-top"
                      src={SingleBPhotoUrl}
                      alt="Other single"
                      style={{
                        width: "150px",
                        height: "150px",
                      }}
                    />
                    <div className="card-body">
                      <p className="card-title h3">{SingleBName}</p>
                      <p className="card-text h6">{SingleBCity} </p>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ paddingTop: "20px" }} id="acceptdecline">
                <Button
                  id="pairBAccept"
                  onClick={() => AcceptButton(true)}
                  style={{ margin: "10px" }}
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  startIcon={<ThumbUp />}
                >
                  Accept
                </Button>
                <Button
                  id="pairBDecline"
                  onClick={() => AcceptButton(false)}
                  variant="contained"
                  color="secondary"
                  size="large"
                  type="submit"
                  startIcon={<ThumbDown />}
                >
                  Decline
                </Button>
              </div>
              <Typography
                variant="h2"
                display="block"
                align="center"
                id="matches-us"
                style={{
                  paddingTop: "0",
                  margin: "0rem",
                  fontSize: "2.5rem",
                  marginBottom: "15px",
                }}
              >
                Match Details
              </Typography>
              <table className="table table-striped" cursor="pointer">
                <thead id="whyMatchMade">
                  <tr>
                    <th scope="col">Man's Preferences</th>
                    <th scope="col">Woman's Traits</th>
                    <th scope="col">Question</th>
                    <th scope="col">Woman's Preferences</th>
                    <th scope="col">Man's Traits</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, idx) => (
                    <tr key={idx}>
                      <td>
                        <a
                          style={{
                            color: getColor(row.womenTrait, row.menPreference),
                          }}
                        >
                          {getWord(row.menPreference)}
                        </a>
                      </td>
                      <td>
                        <a
                          style={{
                            color: getColor(row.womenTrait, row.menPreference),
                          }}
                        >
                          {getWord(row.womenTrait)}
                        </a>
                      </td>
                      <td>
                        <a>{row.question}</a>
                      </td>
                      <td>
                        <a
                          style={{
                            color: getColor(row.menTrait, row.womenPreference),
                          }}
                        >
                          {getWord(row.womenPreference)}
                        </a>
                      </td>
                      <td>
                        <a
                          style={{
                            color: getColor(row.menTrait, row.womenPreference),
                          }}
                        >
                          {getWord(row.menTrait)}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Typography
                variant="h2"
                display="block"
                align="center"
                style={{
                  paddingTop: "25px",
                  margin: "0rem",
                  fontSize: "2.5rem",
                  marginBottom: "15px",
                }}
              >
                Match Rating
              </Typography>
              <form
                autoCorrect="off"
                onSubmit={handleSubmit}
                id="single-form"
                style={{
                  backgroundColor: "transparent",
                  flexDirection: "column",
                  padding: "15px",
                  paddingBottom: "100px",
                  borderRadius: "10px",
                  maxWidth: "1000px",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <div className="form-group col-md">
                  <InputLabel style={{ textAlign: "center" }}>
                    How was the match accuracy?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      required
                      type="radio"
                      value={1}
                      id="rating-radio1"
                      name="rating-radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="rating-radio1">
                      Terrible
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      required
                      type="radio"
                      value={2}
                      id="rating-radio2"
                      name="rating-radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="rating-radio2">
                      Somewhat terrible
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      required
                      type="radio"
                      value={3}
                      id="rating-radio3"
                      name="rating-radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="rating-radio3">
                      In between
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      required
                      type="radio"
                      value={4}
                      id="rating-radio4"
                      name="rating-radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="rating-radio4">
                      Somewhat great
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      required
                      type="radio"
                      value={5}
                      id="rating-radio5"
                      name="rating-radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="rating-radio5">
                      Great
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <InputLabel style={{ textAlign: "left" }}>
                    Feedback <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <TextField
                    required
                    type="text-area"
                    id="description"
                    multiline
                    rows={4}
                    variant="outlined"
                    inputProps={{ maxLength: 500 }}
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="form-group">
                  <Button
                    id="save"
                    variant="contained"
                    style={{ backgroundColor: "#90387E", color: "white" }}
                    size="large"
                    type="submit"
                    endIcon={<Send />}
                  >
                    Submit Review
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default MatchesMade;
