import React, { useState } from "react";
import { Link } from "@reach/router";
import { Button, TextField, Typography, InputLabel } from "@material-ui/core";
import axios from "axios";
import { setUid, setName, setUserEmail, setPhotoUrl } from "../state/app-slice";
import { useAppDispatch } from "../state/hooks/common.hooks";

const SignIn = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = async (event, email, password) => {
    event.preventDefault();
    await axios
      .post(process.env.REACT_APP_APILINK + "login", {
        email: email,
        password: password,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200) {
          dispatch(setName(data.user.displayName));
          dispatch(setUid(data.user.id));
          dispatch(setUserEmail(data.user.email));
          dispatch(setPhotoUrl(data.user.photoUrl));
        } else if (data.status === 404 || data.status === 400) {
          setError("User not found.");
        } else if (data.status === 401) {
          setError("Incorrect password.");
        }
      })
      .catch((e) => {
        setError("Something went wrong. Reload the page and try again.");
      });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div className="mt-8">
      <Typography
        variant="h2"
        display="block"
        align="center"
        id="profile-us"
        style={{
          paddingTop: "0",
          paddingBottom: "15px",
          margin: "0rem",
          fontSize: "2.5rem",
        }}
      >
        Sign In
      </Typography>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address:
              </InputLabel>
              <TextField
                type="email"
                id="userEmail"
                name="userEmail"
                variant="outlined"
                onChange={(event) => onChangeHandler(event)}
                placeholder="E.g: jdoe@gmail.com"
                value={email}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Password:</InputLabel>
              <TextField
                type="password"
                id="userPassword"
                variant="outlined"
                name="userPassword"
                placeholder="Your Password"
                onChange={(event) => onChangeHandler(event)}
                value={password}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="text-center">
            <Button
              className="btn btn-success"
              style={{
                margin: 0,
                width: "100%",
                backgroundColor: "#90387E",
                borderColor: "#90387E",
                color: "#FFFFFF",
              }}
              onClick={(event) => {
                signInWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              Sign in
            </Button>
          </div>
        </form>

        <p className="text-center my-3">
          Don't have an account?{" "}
          <Link
            to="/sign-up"
            className="text-blue-500 hover:text-blue-600"
            style={{ color: "#90387E" }}
          >
            Sign up here.
          </Link>{" "}
          <br />{" "}
          <Link
            to="/passwordReset"
            className="text-blue-500 hover:text-blue-600"
            style={{ color: "#90387E" }}
          >
            Forgot Password?
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
