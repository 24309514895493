import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  InputLabel,
  Select,
} from "@material-ui/core";
import { SnackbarAlert } from "./_shared_/Snackbar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Save } from "@material-ui/icons";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import axios from "axios";
import { setAccountCreated } from "../state/app-slice";
import { setPhotoUrl } from "../state/app-slice";
/* eslint-disable */

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

//export default function ProfileForm(props) {
const ProfileForm = (props) => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [title, setTitle] = React.useState("");
  const [reftitle, setReftitle] = React.useState("");
  const [state, setState] = React.useState("");
  const [refstate, setRefstate] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [refcountry, setRefcountry] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [dob, setDOB] = React.useState(new Date("2000-01-01T01:01:01"));
  const [gapyear, setGapyear] = React.useState("");
  const [gapYearSelected, setGapYearSelected] = React.useState(false);
  const [loading, setLoading] = React.useState();
  const [photo, setPhoto] = React.useState(null);
  const [url, setUrl] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState();
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();

  const classes = useStyles();
  const history = useHistory();
  var image = null;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleDateChange = (date) => {
    setDOB(date);
  };

  const handleGapYearChange = (event) => {
    setGapyear(event.target.value);
    setGapYearSelected(event.target.value);
    if (gapYearSelected === "No") {
      document.getElementById("gapyearschool").removeAttribute("required");
      document.getElementById("red").innerHTML = "";
    } else {
      document.getElementById("gapyearschool").setAttribute("required", "");
      document.getElementById("red").innerHTML = "*";
    }
  };

  const handleReftitleChange = (event) => {
    setReftitle(event.target.value);
  };

  const handleRefstateChange = (event) => {
    setRefstate(event.target.value);
  };

  const handleRefcountryChange = (event) => {
    setRefcountry(event.target.value);
  };

  const handlePhotoChange = (event) => {};

  const initializeForm = () => {
    let page = window.location.href;

    if (!appState.uid || appState.uid === "") {
      window.alert("You are not signed in");
      window.location.replace("/");
    } else {
      // document.getElementById("email").readOnly = true;
      // document.getElementById("email").style.backgroundColor = "lightgrey";
      if (appState.accountCreated === true) {
        const promise = axios
          .get(
            process.env.REACT_APP_APILINK +
              "getShadchansProfile/" +
              appState.uid
          )
          .then(function (response) {
            if (response.data.photoUrl) {
              dispatch(setPhotoUrl(response.data.photoUrl));
            }
            document.getElementById("firstname").value =
              response.data.firstName;
            document.getElementById("lastname").value = response.data.lastName;
            document.getElementById("city").value = response.data.city;
            if (appState.userEmail != "") {
              document.getElementById("email").value = appState.userEmail;
            } else {
              document.getElementById("email").value = response.data.email;
            }

            document.getElementById("number").value = response.data.cellPhone;
            document.getElementById("gapyearschool").value =
              response.data.gapyearschool;
            document.getElementById("description-main").innerHTML =
              response.data.description;
            //document.getElementById("last-updated").innerHTML = "Last Updated " +new Date(response.data.createdAt.seconds);
            document.getElementById("name-main").innerHTML =
              response.data.firstName + " " + response.data.lastName;
            //var date = new Date(response.data.dob.seconds * 1000);
            //setDOB(date);
            document.getElementById("highschool").value =
              response.data.highSchool;
            // document.getElementById("gapyearschool").value = response.data.gapYearSchool;
            document.getElementById("college").value = response.data.college;
            document.getElementById("degree").value =
              response.data.collegeDegree;
            document.getElementById("synagogue").value = response.data.shul;
            // document.getElementById("path").value = response.data.photoURL;
            document.getElementById("description").value =
              response.data.description;
            setTitle(response.data.title);
            setState(response.data.state);
            setCountry(response.data.country);
            setGender(response.data.gender);
            setGapyear(response.data.gapYear);
            setDOB(response.data.dob);
            if (response.data.reference != null) {
              setReftitle(response.data.reference.title);
              setRefstate(response.data.reference.state);
              setRefcountry(response.data.reference.country);
              document.getElementById("reference-firstname").value =
                response.data.reference.firstname;
              document.getElementById("reference-lastname").value =
                response.data.reference.lastname;
              document.getElementById("reference-city").value =
                response.data.reference.city;
              document.getElementById("reference-email").value =
                response.data.reference.email;
              document.getElementById("reference-number").value =
                response.data.reference.cellPhone;
            }
          });
      } else {
        let url_string = window.location.href;
        if (url_string.includes("/profile")) {
          window.alert("You are not signed in");
          window.location.replace("/");
        } else {
          document.getElementById("email").value = appState.userEmail;
          // document.getElementById("email").readOnly = true;
          document.getElementById("email").style.backgroundColor = "lightgrey";
        }
      }
    }
  };
  useEffect(() => {
    initializeForm();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading("loading");
    const titleName = document.getElementById("title").value;
    const firstName = document.getElementById("firstname").value;
    const lastName = document.getElementById("lastname").value;
    const city = document.getElementById("city").value;
    const email = document.getElementById("email").value;
    const number = document.getElementById("number").value;
    const dob = document.getElementById("dob").value;
    const highschool = document.getElementById("highschool").value;
    const college = document.getElementById("college").value;
    const gapyearschool = document.getElementById("gapyearschool").value;
    const degree = document.getElementById("degree").value;
    const synagogue = document.getElementById("synagogue").value;
    // const path = document.getElementById("path").value;
    const description = document.getElementById("description").value;
    const reffirstname = document.getElementById("reference-firstname").value;
    const reflastname = document.getElementById("reference-lastname").value;
    const refcity = document.getElementById("reference-city").value;
    const refemail = document.getElementById("reference-email").value;
    const refnumber = document.getElementById("reference-number").value;

    //document.getElementById("profile-form").reset();

    const promise = axios
      .post(process.env.REACT_APP_APILINK + "setShadchansProfile", {
        docId: appState.uid,
        title: title,
        photoUrl: appState.photoUrl,
        firstName: firstName,
        lastName: lastName,
        city: city,
        state: state,
        country: country,
        email: email,
        cellPhone: number,
        gender: gender,
        dob: dob,
        highSchool: highschool,
        gapYear: gapyear,
        gapYearSchool: gapyearschool,
        college: college,
        collegeDegree: degree,
        shul: synagogue,
        accountCreated: true,
        description: description,
        reference: {
          title: reftitle,
          firstname: reffirstname,
          lastname: reflastname,
          city: refcity,
          state: refstate,
          country: refcountry,
          email: refemail,
          cellPhone: refnumber,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.id) {
          setLoading("succeeded");
          setOpenSnackbar(true);
          setSnackbarMessage("You have successfully saved your profile.");
          setSnackbarSeverity("success");
          dispatch(setAccountCreated(true));
          document.getElementById("description-main").innerHTML =
            data.description;
          document.getElementById("name-main").innerHTML =
            data.firstName + " " + data.lastName;
          document.getElementById("sign-button").innerHTML =
            "Hello " + data.firstName;
        } else {
          setLoading("failed");
          setOpenSnackbar(true);
          setSnackbarMessage(`Unable to save profile. ${data?.message}`);
          setSnackbarSeverity("error");
        }
      })
      .catch(function (error) {
        setLoading("failed");
        setOpenSnackbar(true);
        setSnackbarMessage(`Unable to save profile. ${error}`);
        setSnackbarSeverity("error");
      });
  };
  return (
    <div
      id="profile-form"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
      // data-aos="fade-right" data-aos-duration="500"
      //data-aos-offset="300"
      //data-aos-easing="ease-in-sine"
      // style={{backgroundImage: `url(${Background})`}}
    >
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="profile-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          My Profile
        </Typography>
        <form
          autoCorrect="off"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          id="profile-form"
          style={{
            backgroundColor: "transparent",
            flexDirection: "column",
            padding: "15px",
            borderRadius: "10px",
            maxWidth: "1000px",
            display: "inline-block",
            width: "100%",
          }}
        >
          <div className="form-row">
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>
                Title <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={title}
                onChange={handleTitleChange}
                required
                type="text"
                variant="outlined"
                id="title"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Title</em>
                </MenuItem>
                <MenuItem value={"Ms"}>Ms</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Rabbi"}>Rabbi</MenuItem>
                <MenuItem value={"Dr"}>Dr</MenuItem>
                <MenuItem value={"Prof"}>Prof</MenuItem>
                <MenuItem value={"Sir"}>Sir</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                First Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                variant="outlined"
                id="firstname"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Last Name <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="lastname"
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                City <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="city"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                State <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                id="state"
                value={state}
                onChange={handleStateChange}
                required
                type="text"
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select State</em>
                </MenuItem>
                <MenuItem value={"AL"}>Alabama</MenuItem>
                <MenuItem value={"AK"}>Alaska</MenuItem>
                <MenuItem value={"AZ"}>Arizona</MenuItem>
                <MenuItem value={"AR"}>Arkansas</MenuItem>
                <MenuItem value={"CA"}>California</MenuItem>
                <MenuItem value={"CO"}>Colorado</MenuItem>
                <MenuItem value={"CT"}>Connecticut</MenuItem>
                <MenuItem value={"DE"}>Delaware</MenuItem>
                <MenuItem value={"FL"}>Florida</MenuItem>
                <MenuItem value={"GA"}>Georgia</MenuItem>
                <MenuItem value={"HI"}>Hawaii</MenuItem>
                <MenuItem value={"ID"}>Idaho</MenuItem>
                <MenuItem value={"IL"}>Illinois</MenuItem>
                <MenuItem value={"IN"}>Indiana</MenuItem>
                <MenuItem value={"IA"}>Iowa</MenuItem>
                <MenuItem value={"KS"}>Kansas</MenuItem>
                <MenuItem value={"KY"}>Kentucky</MenuItem>
                <MenuItem value={"LA"}>Louisiana</MenuItem>
                <MenuItem value={"ME"}>Maine</MenuItem>
                <MenuItem value={"MD"}>Maryland</MenuItem>
                <MenuItem value={"MA"}>Massachusetts</MenuItem>
                <MenuItem value={"MI"}>Michigan</MenuItem>
                <MenuItem value={"MN"}>Minnesota</MenuItem>
                <MenuItem value={"MS"}>Mississippi</MenuItem>
                <MenuItem value={"MO"}>Missouri</MenuItem>
                <MenuItem value={"MT"}>Montana</MenuItem>
                <MenuItem value={"NE"}>Nebraska</MenuItem>
                <MenuItem value={"NV"}>Nevada</MenuItem>
                <MenuItem value={"NH"}>New Hampshire</MenuItem>
                <MenuItem value={"NJ"}>New Jersey</MenuItem>
                <MenuItem value={"NM"}>New Mexico</MenuItem>
                <MenuItem value={"NY"}>New York</MenuItem>
                <MenuItem value={"NC"}>North Carolina</MenuItem>
                <MenuItem value={"ND"}>North Dakota</MenuItem>
                <MenuItem value={"OH"}>Ohio</MenuItem>
                <MenuItem value={"OK"}>Oklahoma</MenuItem>
                <MenuItem value={"OR"}>Oregon</MenuItem>
                <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                <MenuItem value={"RI"}>Rhode Island</MenuItem>
                <MenuItem value={"SC"}>South Carolina</MenuItem>
                <MenuItem value={"SD"}>South Dakota</MenuItem>
                <MenuItem value={"TN"}>Tennessee</MenuItem>
                <MenuItem value={"TX"}>Texas</MenuItem>
                <MenuItem value={"UT"}>Utah</MenuItem>
                <MenuItem value={"VT"}>Vermont</MenuItem>
                <MenuItem value={"VA"}>Virginia</MenuItem>
                <MenuItem value={"WA"}>Washington</MenuItem>
                <MenuItem value={"WV"}>West Virginia</MenuItem>
                <MenuItem value={"WI"}>Wisconsin</MenuItem>
                <MenuItem value={"WY"}>Wyoming</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Country <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={country}
                onChange={handleCountryChange}
                required
                type="text"
                variant="outlined"
                id="country"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Country</em>
                </MenuItem>
                <MenuItem value={"United States"}>United States</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-7">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="email"
                id="email"
                variant="outlined"
                style={{ width: "100%" }}
                // readOnly={true}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Phone Number <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="tel"
                id="number"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>
                Gender <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={gender}
                onChange={handleGenderChange}
                required
                type="text"
                variant="outlined"
                id="gender"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Gender</em>
                </MenuItem>
                <MenuItem value={"Male"}>Male</MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Date of Birth <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="dob"
                  value={dob}
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                High School <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="highschool"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-5">
              <InputLabel style={{ textAlign: "left" }}>
                Israel Gap Year <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                value={gapyear}
                onChange={handleGapYearChange}
                required
                type="text"
                variant="outlined"
                id="gapyear"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Did you take an Israel gap year?</em>
                </MenuItem>
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
                <MenuItem value={"Only a few months"}>Only few months</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Israel Gap Year School{" "}
                <span id="red" style={{ color: "red" }}></span>
              </InputLabel>
              <TextField
                type="text"
                id="gapyearschool"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                College <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="college"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Undergraduate Major (if completed)
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="degree"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Synagogue <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <TextField
                required
                type="text"
                id="synagogue"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          {/* <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{textAlign: "left"}}>Profile Picture <span style={{color:"red"}}>*</span></InputLabel>
              <label style={{ width: "100%"}} htmlFor="upload-photo">
                <input onChange={handlePhotoChange} accept="image/*" style={{ display: 'none'}} id="upload-photo" type="file"/>
                <Button required style={{ width: "100%", height: "56px"}} color="secondary" variant="outlined" component="span" startIcon={<PhotoCamera />}>Upload Photo</Button>
              </label>
            </div>
            <div className="form-group col-md-9">
              <InputLabel style={{textAlign: "left"}}>Selected Photo Path<span style={{color:"red"}}>*</span></InputLabel>
              <TextField disabled required type="text" id="path" variant="outlined" style={{ width: "100%", backgroundColor: "lightgrey" }} />
            </div>
          </div> */}
          <div className="form-group">
            <InputLabel style={{ textAlign: "left" }}>
              Description/Bio <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              required
              type="text-area"
              id="description"
              multiline
              minRows={4}
              variant="outlined"
              inputProps={{ maxLength: 500 }}
              style={{ width: "100%" }}
            />
          </div>
          <Typography
            variant="h2"
            display="block"
            align="center"
            id="reference-us"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              margin: "0rem",
              fontSize: "2.5rem",
            }}
          >
            Reference Information
          </Typography>
          <div className="form-row">
            <div className="form-group col-md-2">
              <InputLabel style={{ textAlign: "left" }}>Title</InputLabel>
              <Select
                value={reftitle}
                onChange={handleReftitleChange}
                type="text"
                variant="outlined"
                id="reference-title"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Title</em>
                </MenuItem>
                <MenuItem value={"Ms"}>Ms</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Rabbi"}>Rabbi</MenuItem>
                <MenuItem value={"Dr"}>Dr</MenuItem>
                <MenuItem value={"Prof"}>Prof</MenuItem>
                <MenuItem value={"Sir"}>Sir</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>First Name</InputLabel>
              <TextField
                type="text"
                variant="outlined"
                id="reference-firstname"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Last Name</InputLabel>
              <TextField
                type="text"
                id="reference-lastname"
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>City</InputLabel>
              <TextField
                type="text"
                id="reference-city"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>State</InputLabel>
              <Select
                value={refstate}
                onChange={handleRefstateChange}
                type="text"
                variant="outlined"
                id="reference-state"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select State</em>
                </MenuItem>
                <MenuItem value={"AL"}>Alabama</MenuItem>
                <MenuItem value={"AK"}>Alaska</MenuItem>
                <MenuItem value={"AZ"}>Arizona</MenuItem>
                <MenuItem value={"AR"}>Arkansas</MenuItem>
                <MenuItem value={"CA"}>California</MenuItem>
                <MenuItem value={"CO"}>Colorado</MenuItem>
                <MenuItem value={"CT"}>Connecticut</MenuItem>
                <MenuItem value={"DE"}>Delaware</MenuItem>
                <MenuItem value={"FL"}>Florida</MenuItem>
                <MenuItem value={"GA"}>Georgia</MenuItem>
                <MenuItem value={"HI"}>Hawaii</MenuItem>
                <MenuItem value={"ID"}>Idaho</MenuItem>
                <MenuItem value={"IL"}>Illinois</MenuItem>
                <MenuItem value={"IN"}>Indiana</MenuItem>
                <MenuItem value={"IA"}>Iowa</MenuItem>
                <MenuItem value={"KS"}>Kansas</MenuItem>
                <MenuItem value={"KY"}>Kentucky</MenuItem>
                <MenuItem value={"LA"}>Louisiana</MenuItem>
                <MenuItem value={"ME"}>Maine</MenuItem>
                <MenuItem value={"MD"}>Maryland</MenuItem>
                <MenuItem value={"MA"}>Massachusetts</MenuItem>
                <MenuItem value={"MI"}>Michigan</MenuItem>
                <MenuItem value={"MN"}>Minnesota</MenuItem>
                <MenuItem value={"MS"}>Mississippi</MenuItem>
                <MenuItem value={"MO"}>Missouri</MenuItem>
                <MenuItem value={"MT"}>Montana</MenuItem>
                <MenuItem value={"NE"}>Nebraska</MenuItem>
                <MenuItem value={"NV"}>Nevada</MenuItem>
                <MenuItem value={"NH"}>New Hampshire</MenuItem>
                <MenuItem value={"NJ"}>New Jersey</MenuItem>
                <MenuItem value={"NM"}>New Mexico</MenuItem>
                <MenuItem value={"NY"}>New York</MenuItem>
                <MenuItem value={"NC"}>North Carolina</MenuItem>
                <MenuItem value={"ND"}>North Dakota</MenuItem>
                <MenuItem value={"OH"}>Ohio</MenuItem>
                <MenuItem value={"OK"}>Oklahoma</MenuItem>
                <MenuItem value={"OR"}>Oregon</MenuItem>
                <MenuItem value={"PA"}>Pennsylvania</MenuItem>
                <MenuItem value={"RI"}>Rhode Island</MenuItem>
                <MenuItem value={"SC"}>South Carolina</MenuItem>
                <MenuItem value={"SD"}>South Dakota</MenuItem>
                <MenuItem value={"TN"}>Tennessee</MenuItem>
                <MenuItem value={"TX"}>Texas</MenuItem>
                <MenuItem value={"UT"}>Utah</MenuItem>
                <MenuItem value={"VT"}>Vermont</MenuItem>
                <MenuItem value={"VA"}>Virginia</MenuItem>
                <MenuItem value={"WA"}>Washington</MenuItem>
                <MenuItem value={"WV"}>West Virginia</MenuItem>
                <MenuItem value={"WI"}>Wisconsin</MenuItem>
                <MenuItem value={"WY"}>Wyoming</MenuItem>
              </Select>
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>Country</InputLabel>
              <Select
                value={refcountry}
                onChange={handleRefcountryChange}
                type="text"
                variant="outlined"
                id="reference-country"
                style={{ marginTop: 0, width: "100%" }}
              >
                <MenuItem value="" disabled>
                  <em>Select Country</em>
                </MenuItem>
                <MenuItem value={"United States"}>United States</MenuItem>
              </Select>
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md-7">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address
              </InputLabel>
              <TextField
                type="email"
                id="reference-email"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Phone Number
              </InputLabel>
              <TextField
                type="tel"
                id="reference-number"
                variant="outlined"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-group">
            <Button
              id="save"
              variant="contained"
              style={{ backgroundColor: "#90387E", color: "white" }}
              size="large"
              type="submit"
              className={classes.button}
              startIcon={<Save />}
            >
              {loading === "loading" ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Save Profile"
              )}
            </Button>
          </div>
        </form>
      </div>
      <SnackbarAlert
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
    </div>
  );
};

export default ProfileForm;
// Countries from https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
