import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import FeedbackForm from "../Components/FeedbackForm";

const FeedbackPage = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const history = useHistory();

  useEffect(() => {
    // ComponentWillMount logic
    if (!appState.uid || appState.uid === "") {
      window.alert("You are not signed in");
      history.push("/");
    }
    return () => {};
  }, [history]);

  return (
    <div
      className="page-content"
      id="profile-page"
      data-aos="fade-down"
      data-aos-duration="1500"
    >
      <FeedbackForm />
    </div>
  );
};

export default FeedbackPage;
