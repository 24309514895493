import React from "react";
import ProfileForm from "../Components/ProfileForm";

const AddMatchmaker = () => {
  return (
    <div>
      <div className="page-content" id="profile-page">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
          <div className="container container-fluid text-center">
            <ProfileForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMatchmaker;
