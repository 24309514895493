import React, { useContext } from "react";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
// import UserProvider from "../providers/UserProvider";
import LoginProfile from "./LoginProfile";
import AddMatchmaker from "../Pages/AddMatchmaker";
import { UserContext } from "../providers/UserProvider";
import PasswordReset from "./PasswordReset";
import { useAppSelector } from "../state/hooks/common.hooks";
import "../App";
function Application() {
  const user = useAppSelector((state) => state.app.uid);
  return user ? (
    <LoginProfile />
  ) : (
    <Router>
      <SignUp path="/" />
      <SignIn path="/sign-in" />
      <SignUp path="/sign-up" />
      <AddMatchmaker path="/Add-MatchMaker" />
      <PasswordReset path="/passwordReset" />
    </Router>
  );
}

export default Application;
