export const os = {
  LINUX: "linux",
  MAC: "mac",
  OTHER: "other",
  WINDOWS: "windows",
};

export const getOsVersion = () => {
  if (typeof navigator !== "undefined" && navigator?.userAgent) {
    if (navigator.userAgent.indexOf("Win") !== -1) return os.WINDOWS;
    if (navigator.userAgent.indexOf("Mac") !== -1) return os.MAC;
    if (navigator.userAgent.indexOf("Linux") !== -1) return os.LINUX;
  } else if (typeof process !== "undefined") {
    if (process.platform === "win32") return os.WINDOWS;
    if (process.platform === "darwin") return os.MAC;
    if (process.platform === "linux") return os.LINUX;
  }
  return os.OTHER;
};
