import { configureStore } from "@reduxjs/toolkit";

import app from "./app-slice";

// export const createStore = (options?: {[key: string]: any}) =>
//     configureStore({
//         reducer: { app },
//         middleware: getDefaultMiddleware =>
//           getDefaultMiddleware({
//             serializableCheck: false,
//           }),
//         ...options,
//     });

// export const store = createStore();

// export type TRootState = ReturnType<typeof store.getState>;
// export type TAppDispatch = typeof store.dispatch;

export const createStore = (options) =>
  configureStore({
    reducer: { app },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    ...options,
  });

export const store = createStore();
