import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";

const FeedbackForm = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [feedbackType, setFeedbackType] = useState("");
  const [severity, setSeverity] = useState("");
  const [feedbackDetails, setFeedbackDetails] = useState("");
  const [submittedFeedback, setSubmittedFeedback] = useState(false);
  const [error, setError] = useState("");

  const handleFeedbackTypeChange = (event) => {
    setFeedbackType(event.target.value);
    if (event.target.value !== "Bug Report") {
      setSeverity("");
    }
  };

  const handleSeverityChange = (event) => {
    setSeverity(event.target.value);
  };

  const handleFeedbackDetailsChange = (event) => {
    setFeedbackDetails(event.target.value);
  };

  const getLabelText = () => {
    switch (feedbackType) {
      case "Bug Report":
        return "Explain the steps that led to the bug and what wasn’t working.";
      case "Feature Request":
        return "Describe the feature you would like to have implemented, please be as precise as possible.";
      default:
        return "Please provide your feedback.";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(process.env.REACT_APP_APILINK + "submitFeedback", {
        uid: appState.uid,
        date: new Date().toLocaleString(),
        email: appState.userEmail,
        feedbackType: feedbackType,
        severity: severity,
        feedback: feedbackDetails,
      })
      .then((res) => res.data)
      .then((data) => {
        console.log(data);
        if (data.status == 200) {
          setSubmittedFeedback(true);
          setSeverity("");
          setFeedbackType("");
          setFeedbackDetails("");
        } else setError(data.message);
      });
  };

  return (
    <div
      id="single"
      className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0"
      width="100%"
    >
      <div className="container container-fluid text-center">
        <Typography
          variant="h2"
          display="block"
          align="center"
          id="single-us"
          style={{
            paddingTop: "0",
            margin: "0rem",
            fontSize: "2.5rem",
          }}
        >
          Submit Feedback
        </Typography>
        <Box
          style={{
            width: "50%",
            marginLeft: "25%",
            paddingTop: "10px",
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <FormControl fullWidth>
            <InputLabel htmlFor="feedback-type">Type of Feedback</InputLabel>
            <Select
              value={feedbackType}
              id="feedback-type"
              onChange={handleFeedbackTypeChange}
              variant="outlined"
            >
              <MenuItem value="Bug Report">Bug Report</MenuItem>
              <MenuItem value="Feature Request">Feature Request</MenuItem>
              <MenuItem value="User Experience">User Experience</MenuItem>
              <MenuItem value="Performance Issues">Performance Issues</MenuItem>
              <MenuItem value="Account and Security">
                Account and Security
              </MenuItem>
              <MenuItem value="Content Accuracy">Content Accuracy</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <div style={{ padding: "10px" }}>
            {feedbackType === "Bug Report" && (
              <FormControl fullWidth>
                <InputLabel>Severity</InputLabel>
                <Select
                  value={severity}
                  label="Severity"
                  onChange={handleSeverityChange}
                  variant="outlined"
                >
                  <MenuItem value="Critical">Critical</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Cosmetic">Cosmetic</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div style={{ padding: "10px" }}>
            <Typography style={{ padding: "5px" }} variant="body1">
              {getLabelText()}
            </Typography>
            <TextField
              multiline
              minRows={4}
              variant="outlined"
              value={feedbackDetails}
              onChange={handleFeedbackDetailsChange}
              style={{
                width: "100%",
              }}
            />
          </div>
          <Button
            id="save-feedback"
            variant="contained"
            style={{ backgroundColor: "#90387E", color: "white" }}
            size="large"
            type="submit"
            startIcon={<Save />}
          >
            Submit Feedback
          </Button>
          {submittedFeedback && (
            <Typography
              style={{ padding: "10px", color: "green" }}
              variant="body1"
            >
              Thanks for your feedback! We'll contact you if we need to.
            </Typography>
          )}
          {error && (
            <Typography
              style={{ padding: "5px", color: "red" }}
              variant="body1"
            >
              {error}
            </Typography>
          )}
        </Box>
      </div>
    </div>
  );
};

export default FeedbackForm;
