import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import { dummyMatch } from "../state/constants";
import {
  setIsMatchesPageTutorial,
  setMatchesInfoDocID,
  setShadchan2,
  setSingle1,
  setSingle2,
} from "../state/app-slice";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const MatchesPage = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows3, setRows3] = useState([]);
  const [matchAlgoRunning, setMatchAlgoRunning] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const history = useHistory();
  const [stepIndex, setStepIndex] = useState(0);

  const steps = [
    {
      target: "#table2",
      content: "Click on one the rows below to view a match's details",
      placement: "top-start",
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
          primaryColor: "#90387E",
        },
      },
      title: "Viewing Match Details",
    },
  ];

  useEffect(() => {
    if (!appState.uid || appState.uid === "") {
      window.alert("You are not signed in");
      window.location.href = "/";
    } else if (!appState.accountCreated) {
      window.alert("Please complete your application");
    } else {
      if (!matchAlgoRunning) {
        loadMatches("Complete", setRows);
        loadMatches("Pending", setRows2);
        loadMatches("Denied", setRows3);
      }
    }
  }, [matchAlgoRunning]);

  const loadMatches = async (status, setRowsFunction) => {
    await axios
      .post(process.env.REACT_APP_APILINK + "getMatchesList", {
        uid: appState.uid,
        status: status,
      })
      .then((res) => res.data)
      .then((data) => {
        const newRows = data.matches_list.map((matches) => {
          const date_ob = new Date(matches.dateMatched);
          const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
          const date = ("0" + date_ob.getDate()).slice(-2);
          const year = date_ob.getFullYear();
          const actualDate = year + "-" + month + "-" + date;

          return {
            single1Name: matches.single1Name,
            single2Name: matches.single2Name,
            dateMatched: actualDate,
            link: "/match-made", // Assuming the link structure
            docID: matches.id,
            percent: matches.percent,
          };
        });
        setRowsFunction(newRows);
        if (
          newRows.length === 0 &&
          status === "Pending" &&
          appState.isTutorial
        ) {
          setRowsFunction([dummyMatch]);
        }
        if (status === "Denied") setIsActive(false);
      });
  };

  const MakeRealPercent = (percent) => {
    return parseFloat(percent).toFixed(1);
  };

  const runManual = () => {
    setMatchAlgoRunning(true);
    window.alert(
      "The algorithm will trigger soon, it may take up to a minute."
    );
    axios
      .post(process.env.REACT_APP_APILINK + "createMatches", {})
      .then(function (response) {
        setMatchAlgoRunning(false);
        window.alert(
          "The Manual Match Algorithm has succeeded. Please do not run more than once a day. This will cause you to have high costs due to Database Access."
        );
      })
      .catch(function (error) {
        setMatchAlgoRunning(false);
        window.alert(
          "Match algorithm did not complete. Please contact an administrator."
        );
      });
  };

  const openMatch = async (link, docID) => {
    if (docID === "fakeMatch") {
      dispatch(setMatchesInfoDocID(docID));
      history.push(link);
    } else {
      await axios
        .get(process.env.REACT_APP_APILINK + "getMatchesProfile/" + docID)
        .then((res) => res.data)
        .then((data) => {
          dispatch(setSingle1(data.single1));
          dispatch(setSingle2(data.single2));
          dispatch(setShadchan2(data.shadchan2));
          dispatch(setMatchesInfoDocID(docID));
        })
        .then(function () {
          history.push(link);
        });
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setIsMatchesPageTutorial(false));
      setStepIndex(0);
      if (rows2.length) openMatch(rows2[0].link, rows2[0].docID);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(nextStepIndex);
    }
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading your content...">
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        locale={{
          last: "Next",
        }}
        run={appState.isMatchesPageTutorial}
        stepIndex={stepIndex}
        scrollOffset={300}
        steps={steps}
        styles={{
          options: {},
        }}
      />
      <div>
        <div
          className="page-content"
          id="matches-page"
          data-aos="fade-down"
          data-aos-duration="1500"
        >
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container container-fluid text-center relative">
              <div className="lg:absolute right-0">
                {matchAlgoRunning ? (
                  <CircularProgress />
                ) : (
                  <Button
                    id="manualRun"
                    variant="contained"
                    size="large"
                    data-toggle="modal"
                    style={{
                      backgroundColor: "#90387E",
                      color: "white",
                    }}
                    endIcon={<PlayCircleOutlineIcon />}
                    onClick={runManual}
                  >
                    Run Match Algorithm
                  </Button>
                )}
              </div>

              <Typography
                variant="h2"
                display="block"
                align="center"
                id="matches-us"
                style={{
                  paddingTop: "0",
                  margin: "0rem",
                  fontSize: "2.5rem",
                  marginBottom: "15px",
                }}
              >
                Matches Accepted
              </Typography>
              <table
                className="table table-hover table-striped mb-24"
                cursor="pointer"
              >
                <thead>
                  <tr>
                    <th scope="col">Match Percentage</th>
                    <th scope="col">My Singles</th>
                    <th scope="col">Other Singles</th>
                    <th scope="col">Date Completed</th>
                  </tr>
                </thead>
                <tbody id="table">
                  {rows.map((row) => (
                    <tr
                      key={row.docID}
                      className="clickable-row"
                      onClick={() => openMatch(row.link, row.docID)}
                    >
                      <td>
                        {" "}
                        <a>{MakeRealPercent(row.percent)}%</a>
                      </td>
                      <td>
                        <a>{row.single1Name}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row.single2Name}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row.dateMatched}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Typography
                variant="h2"
                display="block"
                align="center"
                id="profile-us"
                style={{
                  paddingTop: "0",
                  margin: "0rem",
                  fontSize: "2.5rem",
                  marginBottom: "15px",
                }}
              >
                Matches Suggested
              </Typography>
              <table
                data-page-length="1"
                className="table table-hover table-striped mb-24"
                cursor="pointer"
              >
                <thead>
                  <tr>
                    <th scope="col">Match Percentage</th>
                    <th scope="col">My Singles</th>
                    <th scope="col">Other Singles</th>
                    <th scope="col">Date Matched</th>
                  </tr>
                </thead>
                <tbody id="table2">
                  {rows2.map((row2, index) => (
                    <tr
                      key={row2.docID}
                      id={`pending-match-${index}`}
                      className="clickable-row "
                      onClick={() => openMatch(row2.link, row2.docID)}
                    >
                      <td>
                        {" "}
                        <a>{MakeRealPercent(row2.percent)}%</a>
                      </td>
                      <td>
                        <a>{row2.single1Name}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row2.single2Name}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row2.dateMatched}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Typography
                variant="h2"
                display="block"
                align="center"
                id="profile-us"
                style={{
                  paddingTop: "0",
                  margin: "0rem",
                  fontSize: "2.5rem",
                  marginBottom: "15px",
                }}
              >
                Matches Declined
              </Typography>
              <table
                className="table table-hover table-striped"
                cursor="pointer"
              >
                <thead>
                  <tr>
                    <th scope="col">Match Percentage</th>
                    <th scope="col">My Singles</th>
                    <th scope="col">Other Singles</th>
                    <th scope="col">Date Matched</th>
                  </tr>
                </thead>
                <tbody>
                  {rows3.map((row3) => (
                    <tr
                      key={row3.docID}
                      className="clickable-row"
                      onClick={() => openMatch(row3.link, row3.docID)}
                    >
                      <td>
                        {" "}
                        <a>{MakeRealPercent(row3.percent)}%</a>
                      </td>
                      <td>
                        <a>{row3.single1Name}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row3.single2Name}</a>
                      </td>
                      <td>
                        {" "}
                        <a>{row3.dateMatched}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default MatchesPage;
