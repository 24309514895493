import React from "react";
import Typist from "react-typist";

const MainPageHeader = () => {
  return (
    <div>
      <div className="mainheading">
        <div className="form">
          <div
            className="mt-8"
            style={{
              backgroundColor: "white",
              borderRadius: "25px",
              padding: "30px",
            }}
          >
            <h2 className="lead text-dark">
              This site is a platform to help ease the workflow of a Shadchan
              helping singles find matches.
            </h2>
            <div style={{ marginTop: "20px", color: "#90387E" }}>
              <Typist cursor={{ hideWhenDone: true }} className="lead">
                Automation for Matchmakers.
              </Typist>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainPageHeader;
