export const PLACEHOLDER_PROFILE_IMAGE =
  "https://cdn.vectorstock.com/i/preview-1x/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg";

export const dummyMatch = {
  single1Name: "Mr. Single 2",
  single2Name: "Mrs. Single 2",
  dateMatched: "2024-01-01",
  link: "/match-made",
  docID: "dummyMatch",
  percent: 75,
};

export const TUTORIAL_STEPS = [
  {
    target: "#singlesNavText",
    content: "Click here to see your list of singles",
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    placement: "bottom",
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Your Singles",
    disableScrolling: true,
  },
  {
    target: "#add-singles",
    content: (
      <div>
        There are two ways to add your Singles:
        <br />
        1) Add your Single manually
        <br />
        2) Let your Single add themselves via a link
      </div>
    ),
    title: "Adding Singles",
    placement: "bottom",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    disableScrolling: true,
  },
  {
    target: "#tableRow",
    content: "Click here to edit or view a single's profile",
    placement: "bottom",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Viewing A Single's Profile",
  },
  {
    target: "#matchesNavText",
    content: "Click here to view your list of matches",
    placement: "bottom",
    disableBeacon: true,
    spotlightClicks: true,
    // disableOverlayClose: true,
    // hideCloseButton: true,
    // hideFooter: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Viewing Your Matches",
    advanceOnClick: true,
  },
  {
    target: "#pending-match-0",
    content: "Click here to view the match's details",
    placement: "bottom",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Viewing Match Details",
    disableScrolling: true,
  },
  {
    target: "#cardDeck",
    content:
      "This is where you can view the matched Shadchan's and Single's information.",
    placement: "bottom",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Viewing Match Information",
    disableScrolling: true,
  },
  {
    target: "#shadchan2",
    content: "Click here to chat with the matched Shadchan",
    placement: "bottom",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Viewing Match Information",
  },
  {
    target: "#whyMatchMade",
    content: "This is where you can see why the match was made",
    placement: "bottom",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "See Why We Think These Two are a Great Match!",
  },
  {
    target: "#acceptdecline",
    content: (
      <div>
        This is where you can accept or decline matches that were made
        <br />
        <i>Note: Both Shadchans must accept in order for a match to be made</i>
      </div>
    ),
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Accept or Decline",
  },
  {
    target: "#description",
    content: "This is where you can provide feedback on the match at any time",
    disableBeacon: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: "Provide Match Feedback",
  },
];
