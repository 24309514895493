import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import "./Navigation.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { useAppDispatch, useAppSelector } from "../../state/hooks/common.hooks";
import {
  setIsMatchesMadeTutorial,
  setIsMatchesPageTutorial,
  setIsSinglesPageTutorial,
  setIsTutorial,
} from "../../state/app-slice";
import { TUTORIAL_STEPS } from "../../state/constants";

const SinglesNavigation = ({ history }) => {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);

  const startTutorial = () => {
    dispatch(setIsTutorial(true));
    dispatch(setIsSinglesPageTutorial(true));
    dispatch(setIsMatchesPageTutorial(true));
    dispatch(setIsMatchesMadeTutorial(true));
    history.push("/singles");
  };

  if (appState.isSingle) {
    return null;
  } else {
    return (
      <>
        <div>
          <div id="overlay" className="overlay"></div>
        </div>
        <div id="navigationBar" style={{ paddingBottom: 50 }}>
          <nav
            className="navbar fixed-top navbar-expand-lg navbar-dark"
            id="singlesNavbar"
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              zIndex: 99,
              backgroundColor: "#90387E",
            }}
          >
            <Link
              to="/"
              id="homeNav"
              className="navbar-brand"
              style={{ cursor: "pointer", color: "#E8BDD4" }}
            >
              <img
                src={require("./logo_transparent.png")}
                width="30"
                height="30"
                className="d-inline-block align-top"
                alt=""
              />
              Shadchan
            </Link>
            <button
              id="buttonToggler"
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#singles-navbar"
              aria-controls="singles-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style={{ border: 0 }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="singles-navbar">
              <ul className="navbar-nav mr-auto">
                <li
                  className="nav-item"
                  id="singlesNav"
                  data-toggle="collapse"
                  data-target="#singles-navbar"
                >
                  <Link
                    id="singlesNavText"
                    className="nav-link"
                    style={{ color: "#E8BDD4" }}
                    role="button"
                    to="/singles"
                  >
                    Singles
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="matchesNav"
                  data-toggle="collapse"
                  data-target="#singles-navbar"
                >
                  <Link
                    id="matchesNavText"
                    className="nav-link"
                    style={{ color: "#E8BDD4" }}
                    role="button"
                    to="/matches"
                  >
                    Matches
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="profileNav"
                  data-toggle="collapse"
                  data-target="#singles-navbar"
                >
                  <Link
                    id="profileNavText"
                    className="nav-link"
                    style={{ color: "#E8BDD4" }}
                    role="button"
                    to="/profile"
                  >
                    My Profile
                  </Link>
                </li>
                <li
                  className="nav-item"
                  id="feedbackNav"
                  data-toggle="collapse"
                  data-target="#singles-navbar"
                >
                  <Link
                    id="profileNavText"
                    className="nav-link"
                    style={{ color: "#E8BDD4" }}
                    role="button"
                    to="/feedback"
                  >
                    Feedback
                  </Link>
                </li>
              </ul>
              <ul
                className="form-inline my-2 my-lg-0"
                data-toggle="collapse"
                data-target="#singles-navbar"
              >
                <div
                  className="btn btn-outline-light"
                  style={{ borderColor: "#E8BDD4", color: "#E8BDD4" }}
                  role="button"
                  onClick={startTutorial}
                >
                  View Tutorial
                </div>
              </ul>
              <ul
                className="form-inline my-2 my-lg-0"
                data-toggle="collapse"
                data-target="#singles-navbar"
              >
                <Link
                  to="/sign-in"
                  className="btn btn-outline-light"
                  style={{ borderColor: "#E8BDD4", color: "#E8BDD4" }}
                  id="sign-button"
                  role="button"
                >
                  Sign In
                </Link>
              </ul>
            </div>
          </nav>
        </div>
      </>
    );
  }
};

export default withRouter(SinglesNavigation);
