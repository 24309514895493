import React from "react";
import SinglesForm from "../Components/SinglesForm";
import Jpg from "../editable-stuff/profile.jpg";

const AddSingle = () => {
  const FirstName = "First";
  const LastName = "Last";
  const Description =
    "Your description will go here. You can write up to 500 characters.";
  const LastUpdated = "Last updated 10/01/2020 at 4:56PM.";
  const photoUrl = { Jpg };
  return (
    <div>
      <div className="page-content" id="add-single">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
          <div className="container container-fluid text-center">
            <div className="card mb-3" style={{ maxWidth: "1138px" }}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img
                    src={photoUrl}
                    className="img-responsive img-thumbnail rounded-circle mx-auto"
                    style={{
                      marginTop: "0px",
                      height: "250px",
                      width: "250px",
                    }}
                    alt="..."
                  />
                </div>
                <div className="col-md-9">
                  <div className="card-body">
                    <h2 className="h2" id="name-main">
                      {FirstName} {LastName}
                    </h2>
                    <p className="card-text" id="description-main">
                      {Description}
                    </p>
                    <p className="card-text">
                      <small className="text-muted" id="last-updated">
                        {LastUpdated}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <SinglesForm />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddSingle;
