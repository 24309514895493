import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import Jpg from "../editable-stuff/404.jpg";
import "../App";
import { useAppDispatch } from "../state/hooks/common.hooks";
import { setUid } from "../state/app-slice";

const SuccessSignUp = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setUid(undefined));
  }, []);
  return (
    <div>
      <div
        id="404"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-dark d-flex align-content-center align-items-center"
      >
        <div className="container container-fluid text-center ">
         
          <h1 className="display-1" style={{color: "grey", marginTop: "30px", marginBottom: "30px"}}>Thank You for signing up</h1>
          <Typist cursor={{ hideWhenDone: true }}>
            <span className="lead" style={{color: "grey"}}>Your shadchan will contact you with any matches that become available</span>
          </Typist>
          <br></br>
           </div>
      </div>
    </div>
  );
};

export default SuccessSignUp;