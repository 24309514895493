import React, { useState, useEffect } from "react";
import SinglesForm from "../Components/SinglesForm";
import LoadingOverlay from "react-loading-overlay";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";

const SinglesProfile = () => {
  const appState = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const [photo, setPhoto] = useState(null);
  const [isActive, setIsActive] = useState(true);

  const loadImage = (def = false) => {};

  const handlePhotoChange = (event) => {};

  const getPhoto = (event, def = false) => {};

  useEffect(() => {
    setIsActive(false);
  }, []);

  return (
    <LoadingOverlay active={isActive} spinner text="Loading your content...">
      <div className="page-content" id="singles-profile">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
          <div className="container container-fluid text-center">
            <SinglesForm />
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default SinglesProfile;
