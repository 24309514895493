import { createSlice } from "@reduxjs/toolkit";
import { getOsVersion } from "./get-os-version";
import { isMobile } from "react-device-detect";
import { PLACEHOLDER_PROFILE_IMAGE } from "./constants";

const formQuestions = [
  "Are you Quiet?",
  "Are you Outgoing?",
  "Are you Funny?",
  "Are you Intellectual?",
  "Are you Athletic?",
  "Do you watch Movies/TV?",
  "Do you learn Torah regularly?",
  "Do you attend Minyan daily?",
  "Are you stringent with Halacha?",
  "Do you desire making Aliyah?",
];

const initialAppState = {
  uid: "",
  photoUrl: PLACEHOLDER_PROFILE_IMAGE,
  displayName: "",
  singleuid: "",
  accountCreated: false,
  shadchan2: "",
  single1: "",
  single2: "",
  matchesInfoDocID: "",
  userEmail: "",
  isSingle: false,
  numQuestions: formQuestions.length,
  isTutorial: false,
  isSinglesPageTutorial: false,
  isMatchesPageTutorial: false,
  isMatchesMadeTutorial: false,
  osVersion: getOsVersion(),
  isMobile: isMobile,
  singlesPageLoaded: false,
  questions: formQuestions,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    setUid: (state, action) => {
      state.uid = action.payload;
    },
    setPhotoUrl: (state, action) => {
      state.photoUrl = action.payload;
    },
    setName: (state, action) => {
      state.displayName = action.payload;
    },
    setSingleUid: (state, action) => {
      state.singleuid = action.payload;
    },
    setAccountCreated: (state, action) => {
      state.accountCreated = action.payload;
    },
    setShadchan2: (state, action) => {
      state.shadchan2 = action.payload;
    },
    setSingle1: (state, action) => {
      state.single1 = action.payload;
    },
    setSingle2: (state, action) => {
      state.single2 = action.payload;
    },
    setMatchesInfoDocID: (state, action) => {
      state.matchesInfoDocID = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setIsSingle: (state, action) => {
      state.isSingle = action.payload;
    },
    setNumQuestions: (state, action) => {
      state.numQuestions = action.payload;
    },
    setIsTutorial: (state, action) => {
      state.isTutorial = action.payload;
    },
    setIsSinglesPageTutorial: (state, action) => {
      state.isSinglesPageTutorial = action.payload;
    },
    setIsMatchesPageTutorial: (state, action) => {
      state.isMatchesPageTutorial = action.payload;
    },
    setIsMatchesMadeTutorial: (state, action) => {
      state.isMatchesMadeTutorial = action.payload;
    },
    setSinglesPageLoaded: (state, action) => {
      state.singlesPageLoaded = action.payload;
    },
    reset: () => initialAppState,
  },
});

export const {
  setUid,
  setPhotoUrl,
  setName,
  setUserEmail,
  setIsSingle,
  setSingleUid,
  setIsTutorial,
  setIsSinglesPageTutorial,
  setIsMatchesPageTutorial,
  setIsMatchesMadeTutorial,
  setAccountCreated,
  setSingle1,
  setSingle2,
  setShadchan2,
  setMatchesInfoDocID,
  setSinglesPageLoaded,
  reset,
} = appSlice.actions;

export default appSlice.reducer;
