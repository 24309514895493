import React, { useState } from "react";
import ProfileForm from "../Components/ProfileForm";
import { PhotoCamera } from "@material-ui/icons";
import { CircularProgress, Button, FormControl } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { useAppDispatch, useAppSelector } from "../state/hooks/common.hooks";
import { setPhotoUrl } from "../state/app-slice";
import axios from "axios";

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const appState = useAppSelector((state) => state.app);
  const FirstName = "First";
  const LastName = "Last";
  const Description =
    "Your description will go here. You can write up to 500 characters.";
  const LastUpdated = "";

  const [loading, setLoading] = useState();

  const showModal = () => {
    if (appState.isTutorial) {
      if (!isMobile) {
      } else {
        window.alert("To view the tutorial please use the desktop version.");
      }
    }
  };

  const handlePhotoChange = async (event) => {
    event.preventDefault();
    setLoading("loading");
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("shadchanId", appState.uid);
    await axios
      .post(process.env.REACT_APP_APILINK + "uploadShadchanPhoto", formData)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200 && data.photoUrl) {
          setTimeout(async () => {
            dispatch(setPhotoUrl(data.photoUrl));
            setLoading("succeeded");
          }, 1000);
        } else {
          setLoading("failed");
          alert("Image failed to upload. Try again.");
        }
      });
  };

  return (
    <div
      className="page-content"
      id="profile-page"
      data-aos="fade-down"
      data-aos-duration="1500"
    >
      <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
        <div className="container container-fluid text-center">
          <div
            id="finishTutorial"
            className="card mb-3"
            style={{ maxWidth: "1138px" }}
          >
            <div className="row no-gutters">
              <div className="col-md-3">
                {loading === "loading" ? (
                  <div
                    style={{
                      marginTop: "0px",
                      height: "250px",
                      width: "250px",
                    }}
                  >
                    <CircularProgress style={{ marginTop: "50%" }} />
                  </div>
                ) : (
                  <img
                    src={`${appState.photoUrl}?time=${new Date().getTime()}}`}
                    className="img-responsive img-thumbnail rounded-circle mx-auto"
                    style={{
                      marginTop: "0px",
                      height: "250px",
                      width: "250px",
                    }}
                    alt="Profile Pic"
                    id="profile-image"
                  />
                )}
                <FormControl className="row-md-3">
                  <label style={{ width: "100%" }} htmlFor="upload-photo">
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="upload-photo"
                      type="file"
                      onChange={handlePhotoChange}
                    />
                    <Button
                      required
                      size="medium"
                      style={{ color: "#90387E", borderColor: "#90387E" }}
                      variant="outlined"
                      component="span"
                      startIcon={<PhotoCamera />}
                    >
                      Upload Photo
                    </Button>
                  </label>
                </FormControl>
              </div>
              <div className="col-md-9">
                <div className="card-body">
                  <h2 className="h2" id="name-main">
                    {FirstName} {LastName}
                  </h2>
                  <p className="card-text" id="description-main">
                    {Description}
                  </p>
                  <p className="card-text">
                    <small className="text-muted" id="last-updated">
                      {LastUpdated}
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ProfileForm show={showModal} />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
