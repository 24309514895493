import React, { useState } from "react";
import axios from "axios";
import { Link } from "@reach/router";
import { TextField, Typography, InputLabel } from "@material-ui/core";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  // const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const onCurrentPasswordChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userCurrentPassword") {
      setCurrentPassword(value);
    }
  };

  const onNewPasswordChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userNewPassword") {
      setNewPassword(value);
    }
  };

  const onConfirmPasswordChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userConfirmNewPassword") {
      setConfirmNewPassword(value);
    }
  };

  const resetPassword = async (event) => {
    event.preventDefault();
    if (
      newPassword &&
      confirmNewPassword &&
      newPassword !== confirmNewPassword
    ) {
      setError("Passwords don't match.");
      return;
    }
    await axios
      .post(process.env.REACT_APP_APILINK + "resetPassword", {
        email,
        currentPassword,
        newPassword,
      })
      .then((res) => res.data)
      .then((data) => {
        if (data.status === 200) {
          setResetSuccess(true);
          setError(null);
        } else {
          setResetSuccess(false);
          setError(data.message);
        }
      })
      .catch((e) => {
        setError("Error resetting password");
      });
  };
  return (
    <div className="mt-8">
      <Typography
        variant="h2"
        display="block"
        align="center"
        id="profile-us"
        style={{
          paddingTop: "0",
          paddingBottom: "15px",
          margin: "0rem",
          fontSize: "2.5rem",
        }}
      >
        Reset your Password
      </Typography>
      <div className="border border-blue-300 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        <form action="">
          {resetSuccess && (
            <div className="py-3 bg-green-400 w-full text-white text-center mb-3">
              Your password has been reset!
            </div>
          )}
          {error !== null && (
            <div className="py-3 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Email Address:
              </InputLabel>
              <TextField
                type="email"
                id="userEmail"
                name="userEmail"
                variant="outlined"
                onChange={onChangeHandler}
                placeholder="Input your email"
                value={email}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Current Password:
              </InputLabel>
              <TextField
                type="password"
                id="userCurrentPassword"
                name="userCurrentPassword"
                variant="outlined"
                onChange={onCurrentPasswordChangeHandler}
                placeholder="Input your current password"
                value={currentPassword}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                New Password:
              </InputLabel>
              <TextField
                type="password"
                id="userNewPassword"
                name="userNewPassword"
                variant="outlined"
                onChange={onNewPasswordChangeHandler}
                placeholder="Input your new password"
                value={newPassword}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="form-row" style={{ textAlign: "center" }}>
            <div className="form-group col-md">
              <InputLabel style={{ textAlign: "left" }}>
                Confirm New Password:
              </InputLabel>
              <TextField
                type="password"
                id="userConfirmNewPassword"
                name="userConfirmNewPassword"
                variant="outlined"
                onChange={onConfirmPasswordChangeHandler}
                placeholder="Confirm new password"
                value={confirmNewPassword}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-info"
              style={{
                margin: 0,
                width: "100%",
                backgroundColor: "#90387E",
                borderColor: "#90387E",
              }}
              onClick={(event) => {
                resetPassword(event);
              }}
            >
              Reset Password
            </button>
          </div>
        </form>

        <Link
          to="/"
          className="my-2 text-blue-700 hover:text-blue-800 text-center block"
          style={{ color: "#90387E" }}
        >
          &larr; back to sign in page
        </Link>
      </div>
    </div>
  );
};

export default PasswordReset;
